export const homeMenu = {};

export const dashboardMenu = {};

export const demoPages = {};

export const layoutMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/home',
		icon: 'Dashboard',
		subMenu: null,
	},
	layoutTypes: {
		id: 'layoutTypes',
		text: 'Page Layout Types',
	},
	pageLayout: {
		id: 'pageLayout',
		text: 'Page Layout',
		path: 'page-layouts',
		icon: 'BackupTable',
		subMenu: {
			onlyHeader: {
				id: 'onlyHeader',
				text: 'Only Header Test',
				path: 'page-layouts/only-header',
				icon: 'ViewStream',
			},
		},
	},
};

export const componentsMenu = {};

export const productsMenu = {};
