import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function useAuth() {
	return JSON.parse(localStorage.getItem('accessToken'));
}
const PrivateRoute = ({ children }) => {
	const auth = useAuth();
	return auth ? children : <Navigate to='/login' replace />;
};
PrivateRoute.propTypes = {
	children: PropTypes.node.isRequired,
};

export default PrivateRoute;
