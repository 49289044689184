import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/CRUD')),
};

const Feature = {
	Customer: lazy(() => import('../views/v2/DataCustomer')),
};

const MasterData = {
	NewCustomer: lazy(() => import('../views/v2/azhar/masterData/Customer')),
	Product: lazy(() => import('../views/v2/handri/masterData/Product')),
};

const Transaction = {
	ImportTransaction: lazy(() => import('../views/v2/rio/ImportTransaction')),
	ImportRawTransaction: lazy(() => import('../views/v2/rio/ImportRawTransaction')),
	Adjustment: lazy(() => import('../views/v2/azhar/transaction/Adjustment')),
};

const contents = [
	{
		path: null,
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},
	{
		path: null,
		element: <Feature.Customer />,
		index: 'Customer',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.NewCustomer />,
		index: 'NewCustomer',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Product />,
		index: 'Product',
		exact: true,
	},
	{
		path: null,
		element: <Transaction.ImportTransaction />,
		index: 'ImportTransaction',
		exact: true,
	},
	{
		path: null,
		element: <Transaction.ImportRawTransaction />,
		index: 'ImportRawTransaction',
		exact: true,
	},
	{
		path: null,
		element: <Transaction.Adjustment />,
		index: 'AdjustTransaction',
		exact: true,
	},
];
export default contents;
