import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;
const APP_MODEL = 'complain';

const create = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${APP_MODEL}`, payload, { headers: await authHeader() });
};

export default { create };
